import {
  CurrencyProfileActionTypes,
} from './actionTypes';

import {
  EXAMPLE_ACTION,
  CHANGE_THEME,
  CHANGE_SYMBOL,
  CHANGE_COIN,
  GET_NEWS_SUCCESS,
} from './constants';

import { News } from './models';

export interface CurrencyProfileState {
    coin: string
    symbol: string,
    news: News[]
    theme: string
}

export const initialState: CurrencyProfileState = {
  coin: 'ETH',
  symbol: 'BTC-USDT',
  news: [],
  theme: 'light',
};

export function currencyProfileReducer(
  state = initialState,
  action: CurrencyProfileActionTypes,
): CurrencyProfileState {
  switch (action.type) {
    case EXAMPLE_ACTION:
      return {
        ...state,
        news: action.payload,
      };
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case CHANGE_SYMBOL:
      return {
        ...state,
        symbol: action.payload,
      };
    case CHANGE_COIN:
      return {
        ...state,
        coin: action.payload,
      };
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
      };
    default:
      return state;
  }
}
