export const REDIRECT_TO_BILLING = 'REDIRECT_TO_BILLING';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const SIGN_UP_LOADING = 'SIGN_UP_LOADING';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const APPLY_FILTERS_SUCCESS = 'APPLY_FILTERS_SUCCESS';
export const APPLY_FILTERS_FAILURE = 'APPLY_FILTERS_FAILURE';
export const FETCH_EXCHANGES_SUCCESS = 'FETCH_EXCHANGES_SUCCESS';
export const FETCH_EXCHANGES_FAILURE = 'FETCH_EXCHANGES_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const CHANGE_SUBSCRIPTION_SUCCESS = 'CHANGE_SUBSCRIPTION_SUCCESS';
export const CHANGE_SUBSCRIPTION_FAILURE = 'CHANGE_SUBSCRIPTION_FAILURE';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';
export const VALIDATE_COUPON_SUCCESS = 'VALIDATE_COUPON_SUCCESS';
export const VALIDATE_COUPON_FAILURE = 'VALIDATE_COUPON_FAILURE';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';
export const LOGOUT = 'LOGOUT';
export const OAUTH_LOGOUT = 'OAUTH_LOGOUT';
export const TEST = 'TEST';
