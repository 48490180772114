export const EXAMPLE_ACTION = 'EXAMPLE_ACTION';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_SYMBOL = 'CHANGE_SYMBOL';
export const CHANGE_COIN = 'CHANGE_COIN';
export const TEST = 'TEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';

// Labels
export const SELECT_ALL = 'Select all';
export const SELECT_CURRENCY = 'Select Currency';

// Error messages
export const NOT_AVAILABLE = 'Not available.';
