/* eslint-disable import/no-named-default */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import * as React from 'react';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import '@datadog/browser-rum/bundle/datadog-rum';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../src/store/reducers';
import GlobalStyle from '../src/ui/globalStyles';
import { default as lightTheme } from '../src/ui/themes/light';
import { default as darkTheme } from '../src/ui/themes/dark';

let composeEnhancers = compose;
if (process.env.REACT_APP_ENV === 'dev') {
  const composeWithDevToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware)),
);

type SelectedContextType = {
  mode: string | null,
  // eslint-disable-next-line no-unused-vars
  changeValue: (arg: string | null) => void
}

const ThemeContext = React.createContext<SelectedContextType | undefined>(undefined);

export const useSelectedContext = () => React.useContext(ThemeContext) as SelectedContextType;

function DaixApp({ Component, pageProps }: AppProps) {
  const urlPath = React.useRef('');
  const [mode, setMode] = React.useState<SelectedContextType['mode']>('light');
  const changeValue: SelectedContextType['changeValue'] = (value) => {
    setMode(value);
  };

  const segmentInit = (id: string | null, email: string | null) => {
    if (!process.env.SEGMENT_WRITE_KEY) {
      return;
    }

    try {
      const windowObj : any = window;
      windowObj.analytics.ready(() => {
        windowObj.analytics.page();
        if (!id || !email) {
          const segmentUser = windowObj.analytics.user();
          const anonymousId = segmentUser.anonymousId();
          windowObj.analytics.identify(anonymousId);
        }
        windowObj.analytics.identify(id, { email });
      });
    } catch (e) {
      console.log('error: segment', e);
    }
  };

  const datadogInit = () => {
    const windowObj: any = window;
    if (!(process.env.NEXT_PUBLIC_DATADOG_APP_ID && process.env.NEXT_PUBLIC_DATADOG_CLIENT)) {
      console.log('Datadog configuration missing');
      return;
    }

    windowObj.DD_RUM.init({
      applicationId: `${process.env.NEXT_PUBLIC_DATADOG_APP_ID}`,
      clientToken: `${process.env.NEXT_PUBLIC_DATADOG_CLIENT}`,
      site: 'datadoghq.com',
      service: 'uptick-web',
      env: 'uptick-v2staging',
      version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    windowObj.DD_RUM.startSessionReplayRecording();
  };

  React.useEffect(() => {
    const { dispatch, getState } = store;
    const { account } = getState();
    const { id, email } = account.user || { id: null, email: null };
    const savedTheme: string = localStorage.getItem('theme') || 'light';
    const windowObj : any = window;

    setMode(savedTheme);
    dispatch({ type: 'CHANGE_THEME', payload: savedTheme });
    segmentInit(id, email);
    datadogInit();
    urlPath.current = windowObj.history.state.url;
  }, []);

  React.useEffect(() => {
    const windowObj: any = window;
    if (urlPath.current !== windowObj.history.state.url && windowObj?.analytics) {
      windowObj.analytics.page();
      urlPath.current = windowObj.history.state.url;
    }
  });

  return (
    <ThemeContext.Provider value={{ mode, changeValue }}>
      <ThemeProvider theme={mode === 'light' ? { ...lightTheme, mode } : { ...darkTheme, mode }}>
        <Provider store={store}>
          <>
            <GlobalStyle />
            <Component {...pageProps} />
          </>
        </Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default DaixApp;
