/* eslint-disable camelcase */

import {
  REDIRECT_TO_BILLING,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_LOADING,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_UP_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  APPLY_FILTERS_SUCCESS,
  APPLY_FILTERS_FAILURE,
  FETCH_EXCHANGES_SUCCESS,
  FETCH_EXCHANGES_FAILURE,
  CHANGE_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  LOGOUT,
  OAUTH_LOGOUT,
  UPDATE_USER_SUCCESS,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
} from './constants';
import { UserPayload, BillingPayload } from './models';

interface AccountState {
  user?: UserPayload;
  billing?: BillingPayload;
  active?: boolean;
  plan?: string;
  signInLoading?: boolean;
  signUpLoading?: boolean;
  access_token?: string;
  id_token?: string;
  refresh_token?: string;
  validated_at?: string;
  expires_at?: string;
  error?: string;
  loggedIn?: boolean;
  resetPWSuccess?: boolean;
  exchanges?: string[];
  exchange_filter?: string[];
  group?: string;
  billingPeriodEnd?: Date | null;
  changeSubscriptionError?: string;
  cancelSubscriptionError?: string;
  updatePaymentError?: string;
  deleteAccountError?: string;
}

const defaultError = 'Something went wrong. Please reach out to contact@uptick.co for help.';

const getPlan = (tier: string | undefined) => {
  if (tier === 'Tier2') {
    return 'pro';
  }
  if (tier === 'Tier1') {
    return 'premium';
  }

  return 'basic';
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};

const getInitialState = () => {
  const isClient = typeof window !== 'undefined';
  if (isClient) {
    const prevSession = localStorage.getItem('accountState');
    if (prevSession) {
      return JSON.parse(prevSession);
    }
  }
  return {
    user: {
      id: '',
      email: '',
      given_name: '',
      family_name: '',
      name: '',
      groups: ['Tier0'],
    },
    billing: null,
    active: true,
    plan: getPlan('Tier0'),
    signInLoading: false,
    signUpLoading: false,
    access_token: '',
    id_token: '',
    refresh_token: '',
    validated_at: '',
    expires_at: '',
    error: '',
    loggedIn: false,
    resetPWSuccess: false,
    exchanges: [],
    exchange_filter: [],
    group: '',
    billingPeriodEnd: null,
    changeSubscriptionError: '',
    cancelSubscriptionError: '',
    updatePaymentError: '',
    deleteAccountError: '',
  };
};

const updateLocalStorage = (newState: AccountState) => {
  localStorage.setItem('accountState', JSON.stringify(newState));
};

export const initialState: AccountState = getInitialState();

export function accountReducer(state = initialState, action: any): AccountState {
  let newState = {};
  switch (action.type) {
    case REDIRECT_TO_BILLING:
      return {
        ...state,
        active: false,
      };
    case SUBSCRIBE_SUCCESS:
      newState = {
        ...state,
        user: {
          ...state.user,
          groups: [action.payload.plan],
        },
        plan: getPlan(action.payload.plan),
        group: action.payload.plan,
      };
      updateLocalStorage(newState);
      return newState;
    case SUBSCRIBE_FAILURE:
      newState = {
        ...state,
        user: {
          ...state.user,
          groups: ['Tier0'],
        },
        plan: getPlan('Tier0'),
        group: 'Tier0',
      };
      updateLocalStorage(newState);
      return newState;
    case SIGN_IN_SUCCESS: {
      const {
        user, billing, access_token, id_token, refresh_token,
        validated_at, expires_at, exchange_filter,
      } = action.payload;
      const { groups } = user;
      const group = groups ? groups[0] : 'Tier0';
      const { email } = user;

      let billingPeriodEnd = null;
      if (billing) {
        billingPeriodEnd = billing.current_period_end;
      }

      newState = {
        ...state,
        user,
        billing,
        access_token,
        id_token,
        refresh_token,
        validated_at,
        error: '',
        loggedIn: true,
        exchange_filter,
        expiry: expires_at,
        expires_at,
        group,
        billingPeriodEnd,
      };

      const windowObj = window as any;
      if (windowObj && windowObj.analytics) {
        windowObj.analytics.identify(action.payload.user.id, {
          email,
        });
      }

      updateLocalStorage(newState);
      return newState;
    }
    case SIGN_IN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case SIGN_IN_LOADING:
      return {
        ...state,
        signInLoading: action.payload,
      };
    case REFRESH_TOKEN_SUCCESS:
    case SIGN_UP_SUCCESS: {
      newState = {
        ...state,
        user: {
          id: action.payload.id,
          email: action.payload.username,
          given_name: '',
          family_name: '',
          name: '',
          groups: ['Tier0'],
        },
        plan: getPlan('Tier0'),
        group: 'Tier0',
        loggedIn: true,
        error: '',
      };

      const windowObj = window as any;
      if (windowObj && windowObj.analytics) {
        windowObj.analytics.identify(action.payload.id, {
          email: action.payload.username,
        });
      }

      updateLocalStorage(newState);
      return newState;
    }
    case SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case SIGN_UP_LOADING:
      return {
        ...state,
        signUpLoading: action.payload,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPWSuccess: false,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPWSuccess: true,
        error: '',
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case APPLY_FILTERS_SUCCESS:
      newState = {
        ...state,
        exchange_filter: action.payload.exchange_filter,
      };
      updateLocalStorage(newState);
      return newState;
    case APPLY_FILTERS_FAILURE:
      return {
        ...state,
        exchange_filter: [],
      };
    case FETCH_EXCHANGES_SUCCESS: {
      newState = {
        ...state,
        exchanges: action.payload,
      };
      updateLocalStorage(newState);
      return newState;
    }
    case FETCH_EXCHANGES_FAILURE: {
      return {
        ...state,
        exchanges: [],
      };
    }
    case UPDATE_USER_SUCCESS: {
      newState = {
        ...state,
        user: {
          ...state.user,
          given_name: action.payload.first_name,
          family_name: action.payload.last_name,
          name: action.payload.name,
        },
      };
      updateLocalStorage(newState);
      return newState;
    }
    case CHANGE_SUBSCRIPTION_SUCCESS: {
      const {
        customer_id,
        payment_method_id,
        current_period_end,
        current_period_start,
        tier,
      } = action.payload;

      const newBilling = {
        customer_id,
        payment_method_id,
        current_period_start,
        current_period_end,
        tier,
        active: true,
        cancel_at: null,
      };

      newState = {
        ...state,
        user: {
          ...state.user,
          groups: [tier],
        },
        billing: newBilling,
        plan: getPlan(tier),
        billingPeriodEnd: current_period_end,
        group: tier,
        changeSubscriptionError: '',
      };

      updateLocalStorage(newState);
      return newState;
    }
    case CHANGE_SUBSCRIPTION_FAILURE: {
      newState = {
        ...state,
        changeSubscriptionError: action.payload.error || defaultError,
      };
      return newState;
    }
    case CANCEL_SUBSCRIPTION_SUCCESS: {
      const { cancel_at: cancelAt } = action.payload;
      const newBilling = {
        ...state.billing,
        cancel_at: cancelAt,
      };
      newState = {
        ...state,
        billing: newBilling,
      };

      updateLocalStorage(newState);

      return newState;
    }
    case CANCEL_SUBSCRIPTION_FAILURE: {
      newState = {
        ...state,
        cancelSubscriptionError: action.payload.error || defaultError,
      };
      return newState;
    }
    case UPDATE_PAYMENT_SUCCESS: {
      const { payment_method_id } = action.payload;
      const billing = {
        ...state.billing,
        payment_method_id,
      };
      newState = {
        ...state,
        billing,
        updatePaymentError: '',
      };

      updateLocalStorage(newState);

      return newState;
    }
    case UPDATE_PAYMENT_FAILURE: {
      newState = {
        ...state,
        updatePaymentError: action.payload.error || defaultError,
      };
      return newState;
    }
    case DELETE_ACCOUNT_FAILURE: {
      newState = {
        ...state,
        deleteAccountError: action.payload.error || defaultError,
      };
      return newState;
    }
    case LOGOUT:
    case OAUTH_LOGOUT:
      localStorage.removeItem('accountState');
      return {
        user: {
          id: '',
          email: '',
          given_name: '',
          family_name: '',
          name: '',
          groups: ['Tier0'],
        },
        billing: undefined,
        active: true,
        plan: getPlan('Tier0'),
        signInLoading: false,
        signUpLoading: false,
        access_token: '',
        id_token: '',
        refresh_token: '',
        validated_at: '',
        expires_at: '',
        error: '',
        loggedIn: false,
        resetPWSuccess: false,
        exchanges: [],
        exchange_filter: [],
        group: '',
        billingPeriodEnd: null,
        changeSubscriptionError: '',
        cancelSubscriptionError: '',
        updatePaymentError: '',
        deleteAccountError: '',
      };
    default:
      return state;
  }
}
