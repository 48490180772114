import {
  space,
  colors,
  letterSpacings,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  headings,
} from './common';

const site = {
  background: '#FCFDFE', // colors.white,
  color: colors.black,
  bannerBackground: colors.white,
  tableHeaderBackground: 'rgba(217, 217, 217, 0.25)',
  bannerBorder: '#D9D9D9',
  selectBorder: '#D9D9D9',
  selectTabBorder: '#D9D9D9',
  tabTitleColor: colors.white,
  filtersBorder: colors.black,
  bannerShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  cellBorder: colors.black,
  cellColor: 'black',
  currencyTabColor: colors.accent,
  selectorText: '#000',
  selectorTextHover: colors.offWhite,
  pageHeaderText: '#647087',
  tickerBorder: '#000',
  chartLabel: '#647087',
  disabled: '#444',
  sectionTitleBackground: '#D9D9D9',
};

const theme = {
  site,
  space,
  colors,
  letterSpacings,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  headings,
};

export default theme;
