import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import { GTheme } from '../themes';

const GlobalStyle = createGlobalStyle<{ theme: GTheme }>`
  ${styledNormalize}
  @import url('https://use.typekit.net/cmv3bym.css');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
  * {
    box-sizing: border-box;
  }
  body {
    background-color: ${({ theme }) => theme.site.background};
    color: ${({ theme }) => theme.site.color};
    font-family: ${({ theme }) => theme.fonts.proximaNova};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeights.copy};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: ${({ theme }) => theme.lineHeights.title};
  }
  a {
    color: ${({ theme }) => theme.colors.tempLink};
    text-decoration: none;
  }
  a:hover {
    opacity: .75;
  }
  img {
    display: block;
  }
  .navFilters {
    padding: 8px;
    display: flex;
    border: 1px solid ${({ theme }) => theme.site.filtersBorder};
    border-radius: 4px;
    min-width: 85px;
    height: 36px;
    cursor: pointer;
    align-items: center;
    margin-right: 40px;
  }
  .ticker { width: 100%; }
  .ticker__element {
    display: flex !important;
  }
  .workspaceContainer {
    min-height: 440px;
    margin-right: 40px;
  }
  .bannerBlock {
    display: flex;
    background: ${({ theme }) => theme.site.bannerBackground};
    border: 1px solid ${({ theme }) => theme.site.bannerBorder};
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.site.bannerShadow};
    height: 72px;
    padding: 16px;
  }
  .bannerBox {
    margin-right: 40px;
    text-align: right;
  }
  @media screen and (max-width: 1300px) {
    .sentimentTabs {
      display: none !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .bannerBlock {
      display: none;
    }
    .orderBookBlock {
      display: none;
    }
    .chart {
      width: 100vw !important;
      margin-left: 0px !important;
      height: 100vh !important;
      margin-top: 0px !important;
    }
    .currencyChart {
      width: 100vw !important;
      margin-left: 0px !important;
      height: 100vh !important;
      margin-top: 0px !important;
    }
    .selectBlock {
      width: 90vw !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .depthChart {
      width: 100vw !important;
      margin-top: 40px !important;
      margin-left: 0px !important;
      margin-bottom: 80px !important;
    }
    .topCurrencyContainer {
      display: none !important;
    }
    .currencyProfileTabs {
      display: flex !important;
      padding-top: 16px;
      padding-bottom: 16px;
      margin-left: 5%;
      margin-right: 5%;
    }
    .currencyProfileTab {
      background: ${({ theme }) => theme.site.bannerBackground};
      border: 1px solid ${({ theme }) => theme.site.bannerBorder};
      box-shadow: ${({ theme }) => theme.site.bannerShadow};
      border-radius: 8px;
      color: ${({ theme }) => theme.site.currencyTabColor};
      margin-right: 8px;
      padding: 12px 16px;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }
    .currencyProfileTabActive {
      background: #55B1EC;
      border: 2px solid #55B1EC;
      color: white;
      border-radius: 8px;
      padding: 12px 16px;
      margin-right: 8px;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }
    .mobileCurrencyContainer {
      display: unset !important;
      width: 100% !important;
      height: 100% !important;
    }
    .currencyCard {
      background:  ${({ theme }) => theme.site.bannerBackground};
      border: 1px solid ${({ theme }) => theme.site.bannerBorder};
      box-shadow: ${({ theme }) => theme.site.bannerShadow};
      border-radius: 8px;
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .cardBanner {
      border-top: 1px solid ${({ theme }) => theme.site.bannerBorder};
      display: flex;
      padding: 16px;
    }
    .currencyMarket {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .mobileCurrencyNamePanel {
      border-bottom: 1px solid ${({ theme }) => theme.site.bannerBorder};
      padding-bottom: 11px;
      display: flex;
      padding-left: 16px;
    }
    .sentiment {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .sentimentTabs {
      margin-left: 0px !important;
    }
    .whitepaper {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .sectionButton {
      max-width: 150px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .newsMobile {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
      background: ${({ theme }) => theme.site.bannerBackground};
      border: 1px solid ${({ theme }) => theme.site.bannerBorder};
      box-shadow: ${({ theme }) => theme.site.bannerShadow};
      border-radius: 8px;
      margin-top: 20px;
    }
    .newsItem {
      border-top: 1px solid ${({ theme }) => theme.site.bannerBorder};
      border-bottom: none !important;
      border-right: none !important;
      border-left: none !important;
      border-radius: 0px !important;
      padding: 16px ;
      width: 100% !important;
    }
    .twitter {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .twitterHeader {
      padding-top: 0px !important;
      text-align: left !important;
    }
    .twitterItemTitle {
      margin-bottom: 8px !important;
    }
    .events {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .eventsHeader {
      border-bottom: 1px solid ${({ theme }) => theme.site.bannerBorder};
    }
    .team {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
      background: ${({ theme }) => theme.site.bannerBackground};
      border: 1px solid ${({ theme }) => theme.site.bannerBorder};
      box-shadow: ${({ theme }) => theme.site.bannerShadow};
      border-radius: 8px;
      margin-top: 20px;
    }
    .teamItem {
      border-right: 0px !important;
      border-left: 0px !important;
      border-radius: 0px !important;
      border-bottom: 0px !important;
      margin-bottom: 0px !important;
      width: 100% !important;
    }
    .github {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
      background: ${({ theme }) => theme.site.bannerBackground};
      border: 1px solid ${({ theme }) => theme.site.bannerBorder};
      box-shadow: ${({ theme }) => theme.site.bannerShadow};
      border-radius: 8px;
      margin-top: 20px;
      padding-bottom: 0px !important;
    }
    .githubMobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 16px;
      padding-left: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.site.bannerBorder};
    }
    .chartTabs {
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .chartTab {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      padding: 8px;
      margin-right: 25px;
      cursor: pointer;
    }
    .chartTabActive {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      padding: 8px 12px;
      margin-right: 25px;
      background: #55B1EC;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      color: white;
      cursor: pointer;
    }
    .volume {
      width: 90% !important;
      margin-right: auto !important;
      margin-left: auto !important;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #CCCCCC;
      margin-top: 17px;
    }
    .bigSelector {
      margin-bottom: 7px !important;
      width: 90% !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .selectList {
      width: 90vw !important;
      margin-left: auto !important;
      margin-right: auto !important;
      left: 0 !important;
      right: 0 !important;
    }
    .mobileTitle {
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
    }
    .currencyTabs {
      display: none !important;
    }
  }
  .hoverInfo {
    background: ${({ theme }) => theme.site.bannerBackground};
    border: 1px solid ${({ theme }) => theme.site.bannerBorder};
    padding: 8px;
    box-shadow: ${({ theme }) => theme.site.bannerShadow};
    border-radius: 8px;
    font-size: 12px;
    line-height: 14px;
    max-width: 6.25vw;
    position: absolute;
    bottom: 77px;
    margin-left: 160px;
  }
  .currencyTabs {
    display: flex !important;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 50px;
  }
  .currencyTab {
    background: ${({ theme }) => theme.site.bannerBackground};
    border: 1px solid ${({ theme }) => theme.site.bannerBorder};
    box-shadow: ${({ theme }) => theme.site.bannerShadow};
    border-radius: 8px;
    color: ${({ theme }) => theme.site.currencyTabColor};
    margin-right: 8px;
    padding: 12px 16px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }
  .currencyTabActive {
    background: #55B1EC;
    border: 2px solid #55B1EC;
    color: white;
    border-radius: 8px;
    padding: 12px 16px;
    margin-right: 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }
  .sentimentChange {
    font-size: 16px;
    line-height: 19px;
    margin-left: 16px;
  }
  .selectBlock {
    min-width: 321px;
    margin-right: 40px;
    margin-bottom: 20px;
  }
  .mobileHeader {
    display: none;
  }
  .selectTitle {
    font-size: 24px;
    line-height: 29px;
  }
  .selector {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    background: ${({ theme }) => theme.site.bannerBackground};
    border: 2px solid ${({ theme }) => theme.site.selectBorder};
    box-shadow: 0px 2px 20px -10px rgba(0, 0, 0, 0.25);
    border-radius: 17.5px;
    min-width: 16.7vw;
  }
  .selector:hover {
    cursor: pointer;
    border: 2px solid #55B1EC;
  }
  .selectText {
    font-size: 16px;
    line-height: 19px;
    margin-left: 12px;
    margin-top: 5px;
  }
  .selectList {
    margin-top: 8px;
    padding: 16px;
    background: ${({ theme }) => theme.site.bannerBackground};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: absolute;
    z-index: 999;
    min-width: 16.71vw;
    max-height: 280px;
    overflow-y: auto;
  }
  .selectListItem:last-child {
    margin-bottom: 0px !important;
  }
  .selectListItem {
    cursor: pointer;
  }
  .search {
    display: flex;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding-left: 13px;
    height: 44px;
    margin-bottom: 15px;
  }
  .searchInput {
    outline: none;
    background: transparent;
    color: ${({ theme }) => theme.site.color};
    border: none;
    font-size: 17px;
    margin-left: 8px;
  }
  .categories {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .category {
    margin-right: 20px;
    font-size: 14px;
    line-height: 17px;
  }
  .category:hover {
    cursor: pointer;
  }
  .listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .listItem:hover {
    cursor: pointer;
  }
  .listTitle {
    font-size: 14px;
    line-height: 17px;
    margin-left: 8px;
  }
  .orderBookBlock {
    background: ${({ theme }) => theme.site.bannerBackground};
    border: 1px solid ${({ theme }) => theme.site.bannerBorder};
    box-shadow: ${({ theme }) => theme.site.bannerShadow};
    border-radius: 8px;
    width: 350px;
    min-width: 350px;
    min-height: 880px;
  }
  .tabs {
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 22px;
  }
  .tabsTitle {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    color: #5F5F5F;
    height: 30px;
    padding: 8px 5.25px;
    min-width: 133px;
  }
  .tabsTitle:hover {
    cursor: pointer;
  }
  .tabsTitleActive {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    color: ${({ theme }) => theme.site.tabTitleColor};
    height: 30px;
    min-width: 133px;
    padding: 8px 5.25px;
    background: #55B1EC;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .tabsTitleActive:hover {
    cursor: pointer;
  }
  .headers {
    display: flex;
    margin-left: 27px;
    margin-bottom: 16px;
  }
  .header {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    margin-right: 40px;
  }
  .orderListItem {
    display: flex;
    border-top: 1px solid ${({ theme }) => theme.site.bannerBorder};
    padding: 16px;
    min-height: 49px;
  }
  .actualPrice {
    text-align: center;
    padding: 20px 90px;
    min-height: 78px;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.02em;
    border-top: 1px solid ${({ theme }) => theme.site.bannerBorder};
  }
  .itemPrice {
    margin-right: 57px;
    font-size: 14px;
    line-height: 17px;
  }
  .itemQuantity {
    margin-right: 70px;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
  }
  .itemTotal {
    font-size: 14px;
    line-height: 17px;
    text-align: right;
  }
  .chart {
    margin-top: 20px;
    max-height: 450px;
  }
  .currencyChart {
    margin-left: 50px;
    margin-top: 20px;
    width: 71vw;
  }
  .depthChart {
    background-color: ${({ theme }) => theme.site.background};
    width: 100%;
    height: 30.85vh;
    font-weight: bold;
    margin-top: 20px;
  }
  .topCurrencyContainer {
    display: flex;
    min-height: 2900px;
  }
  .mobileCurrencyContainer {
    display: none;
  }
  .currencyProfileContainer {
    min-height: 536px;
  }
  .marketTab {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    color: #5F5F5F;
    height: 30px;
    padding: 8px 5.25px;
    margin-right: 20px;
    max-width: 83px;
  }
  .marketTab:hover {
    cursor: pointer;
  }
  .marketTabActive {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    color: ${({ theme }) => theme.site.tabTitleColor};
    height: 30px;
    min-width: 83px;
    padding: 8px 5.25px;
    background: #55B1EC;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-right: 20px;
  }
  .marketTabActive:hover {
    cursor: pointer;
  }
  .new-allocations-slider {
    height: 20px;
    width: 250px;
  }
  .new-allocations-slider-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    .new-allocations-thumb {
      cursor: not-allowed !important;
    }
  }
  .new-allocations-track {
    height: 2px;
    background: white;
    top: 9px;
  }
  .news-allocations-track-1 {
    opacity: 0.2;
  }
  .new-allocations-thumb {
    cursor: pointer;
    background: #D9D9D9;
    border: 1px solid #000000;
    border-radius: 5px;
    width: 60px;
    height: 20px;
    padding: 5px;
    color: black;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .new-allocations-thumb-active {
    outline: none;
    cursor: grabbing;
  }
  .allocations-slider {
    height: 25px;
    width: 360px;
  }
  .allocations-slider-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    .allocations-thumb {
      cursor: not-allowed !important;
    }
  }
  .allocations-track {
    height: 1px;
    margin-top: 12px;
    background: #55B1EC;
  }
  .allocations-track-1 {
    opacity: 0.2;
  }
  .allocations-mark {
    background: #D9D9D9;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .allocations-thumb {
    cursor: pointer;
    width: 0;
    height: 0;
    left: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid #55B1EC;
    bottom: 12px;
  }
  .allocations-thumb-active {
    outline: none;
    cursor: grabbing;
  }
  .slider {
    margin-right: 20px;
    height: 34px;
    width: 450px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .thumb {
    background: #55B1EC;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    width: 24px;
    height: 24px;
  }
  .slider .track {
    height: 34px;
  }
  .thumb.active {
    outline: none;
  }
  #mobileChart {
    width: 100% !important;
    height: 175px !important;
    .tv-lightweight-charts {
      width: 100% !important;
      height: 175px !important;
      table {
        width: 100% !important;
        height: 175px !important;
        canvas {
          width: 100% !important;
          height: 175px !important;
        }
      }
    }
  }
  .github {
    padding-bottom: 100px;
  }
  .topHeaderContainer {
    @media screen and (max-width: 1200px) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .react-calendar-heatmap .color-scale-1 {
    fill: rgba(22, 160, 133, 0.3);
    @media screen and (max-width: 1200px) {
      width: 5px;
      height: 5px;
    }
  }
  .react-calendar-heatmap .color-scale-2 {
    fill: rgba(22, 160, 133, 0.5);
    @media screen and (max-width: 1200px) {
      width: 5px;
      height: 5px;
    }
  }
  .react-calendar-heatmap .color-scale-3 {
    fill: rgba(22, 160, 133, 0.8);
    @media screen and (max-width: 1200px) {
      width: 5px;
      height: 5px;
    }
  }
  .react-calendar-heatmap .color-scale-4 {
    fill: #16A085;
    @media screen and (max-width: 1200px) {
      width: 5px;
      height: 5px;
    }
  }
  .react-calendar-heatmap .color-empty {
    fill: rgba(22, 160, 133, 0.2);
    @media screen and (max-width: 1200px) {
      width: 5px;
      height: 5px;
    }
  }
  .react-calendar {
  width: 100%;
  background: #131722;
  border: 0px solid #a0a096;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  position: relative;
  margin: 0;
  border: 0;
  /* padding: 25px; */
  border: 0 solid #297fb8;
  /* color: white; */
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
  background: none;
}
.react-calendar__navigation {
  height: 40px;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  background: #0A2C43;
}
.react-calendar__navigation button {
  min-width: 44px;
  color: white;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #2d3e50;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__navigation__arrow {
  font-size: 30px;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  color: #bdbdbd;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
}
.react-calendar__month-view__days__day {
  color: white;
}
.react-calendar__month-view__days__day--weekend {
  color: white;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #616161;
}
.react-calendar__tile {
  max-width: 100%;
  background: none;
  aspect-ratio: 1;
  border-radius: 50%;
  justify-content: center;
  font-weight: 900 !important;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: none;
}
.react-calendar__tile--active:enabled:hover abbr,
.react-calendar__tile--active:enabled:focus abbr {
  background: #297fb8;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation__next-button:hover {
  background: #1087ff;
}
button.react-calendar__tile {
  flex: 0 0 14.2857%;
  flex: 0 0 9.5238% !important;
  margin: 2.38095% !important;
  border: 1px solid #131722;
  cursor: pointer;
  background: #131722;
  padding: 0;
  border-radius: 50%;
}
button.react-calendar__tile:enabled:hover {
  flex: 0 0 14.2857%;
  flex: 0 0 9.5238% !important;
  margin: 2.38095% !important;
  border: 1px solid #2d3e50;
  cursor: pointer;
  background: #2d3e50;
  padding: 0;
  border-radius: 50%;
}
button.react-calendar__tile--now {
  border: 2px solid #fff;
}
button.react-calendar__tile--active,
button.react-calendar__tile--active:enabled:focus {
  background: #297fb8;
  border: 2px solid #297fb8;
}
button.react-calendar__navigation__label:disabled {
  background: #0A2C43;
  color: white;
  font-weight: 900;
}
  .currencyProfileTabs {
    display: none;
  }
  .yLabel {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    fill: ${({ theme }) => theme.site.chartLabel};
  }
  .svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 50%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
  }
  .svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
  }
  .barLabel {
    font-size: 10px;
    font-weight: 700;
  }
  #svgContainer {
    @media screen and (max-width: 950px) {
      display: none;
    }
  }
  #svgContainerMobile {
    display: none;
    @media screen and (max-width: 950px) {
      display: block !important;
    }
  }
  .rectLabel {
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    font-weight: bold;
    fill: ${({ theme }) => theme.site.color};
  }
  .meetings-lib {
    margin-right: 0px !important;
  }
  .datePicker {
    color: ${({ theme }) => theme.site.color} !important;
    background: ${({ theme }) => theme.site.bannerBackground} !important;
    border: 1px solid ${({ theme }) => theme.site.bannerBorder} !important;
  }

  .sassy--select__dropdown {
    color: ${({ theme }) => theme.site.color} !important;
    background: ${({ theme }) => theme.site.bannerBackground} !important;
    border: 1px solid ${({ theme }) => theme.site.bannerBorder} !important;
  }

  /* Main Layout Grid */
  .main-layout {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-row-gap: ${({ theme }) => theme.space[4]}px;
    grid-column-gap: ${({ theme }) => theme.space[4]}px;
    grid-template-areas:
      "layout-data layout-data layout-data layout-data layout-data layout-data"
      "layout-main layout-main layout-main layout-main layout-sidebar layout-sidebar"
  }
  .layout-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${({ theme }) => theme.space[4]}px;
    grid-area: layout-data;
  }
  .layout-main {
    grid-area: layout-main;
  }
  .layout-sidebar {
    grid-area: layout-sidebar;
  }
  .layout-data {
    grid-area: layout-data;
  }
  .layout-item {
    margin-bottom: ${({ theme }) => theme.space[4]}px;
  }
  .layout-marketData,
  .layout-currencyData {
    grid-column: span 1;
    margin-bottom: 0;
  }
  .react-switch {
    border: 1px solid #55B1EC;
  }
  @media screen and (max-width: 40em) {
    .main-layout {
      grid-template-rows: auto auto auto auto auto auto auto auto auto;
      grid-template-areas: none;
    }
    /* Because we have to arbitrarily rearrange DOM elements in mobile with only CSS, we have to
    get incredibly verbose and granular to control the order */
    .layout-marketData,
    .layout-currencyData,
    .layout-whitepaper,
    .layout-news,
    .layout-github,
    .layout-team,
    .layout-events,
    .layout-sentiment,
    .layout-useCases,
    .layout-social {
      grid-area: none;
      grid-column: span 6;
      margin-bottom: 0;
    }
    .layout-marketData {
      grid-row: 1 / 2;
    }
    .layout-currencyData {
      grid-row: 2 / 3;
    }
    .layout-whitepaper {
      grid-row: 3 / 4;
    }
    .layout-news {
      grid-row: 4 / 5;
    }
    .layout-social {
      grid-row: 5 / 6;
    }
    .layout-sentiment {
      grid-row: 6 / 7;
    }
    .layout-team {
      grid-row: 7 / 8;
    }
    .layout-github {
      grid-row: 8 / 9;
    }
    .layout-useCases {
      grid-row: 9 / 10;
    }
    .layout-events {
      grid-row: 10 / 11;
    }
}
`;

export default GlobalStyle;
