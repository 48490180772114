/* eslint-disable react/destructuring-assignment */
import {
  SET_STATS_COLUMNS,
  SET_PALETTE,
  STATS_GET_SUCCESS,
  ALERTS_GET_SUCCESS,
  SET_STATS_VISIBILITY,
  APPLY_CONFIG,
  CURRENCIES_GET_SUCCESS,
  SET_EDIT_STAT_ROW,
  CURRENCIES_GET_REQUEST,
  STATS_GET_REQUEST,
  ALERTS_GET_REQUEST,
  LOAD_NEWS_SUCCESS,
  LOAD_REDDIT_SUCCESS,
  LOAD_TWEETS_SUCCESS,
  LOAD_NEWS,
  LOAD_PORTFOLIO,
  LOAD_PORTFOLIO_SUCCESS,
  LOAD_ARTICLE,
  LOAD_ARTICLE_SUCCESS,
  LOAD_SCENARIO,
  LOAD_SCENARIO_SUCCESS, SELECT_SCENARIO,
  GAIN_LOSS_GET_SUCCESS,
  LOAD_ALLOCATIONS,
  LOAD_ALLOCATIONS_SUCCESS,
  SET_PORTFOLIO_ID,
  SET_LOGIN_TOKENS,
  SET_PORTFOLIO_LIST,
  SET_USER_ID,
  LOAD_MARKET_DATA,
  LOAD_MARKET_DATA_SUCCESS,
  LOAD_DRAWDOWN,
  LOAD_DRAWDOWN_SUCCESS,
  SET_CREATE_PORTFOLIO,
  LOAD_BENCHMARK_SUCCESS,
  SET_OPTIMIZATION_PARAMS,
  SET_STRATEGY_LIST,
  SET_SELECTED_STRATEGY,
} from './constants';

const initialState = {
  total: {
    loading: false,
    data: {
      totalInUSD: 0,
      percent: 'N/A',
      gainLoss: {
        change_1d: {
          value: 'N/A',
          percent: 'N/A',
        },
        change_7d: {
          value: 'N/A',
          percent: 'N/A',
        },
        change_30d: {
          value: 'N/A',
          percent: 'N/A',
        },
      },
      dailyIndex: {
        change_1d: {
          value: 'N/A',
          percent: 'N/A',
        },
        change_7d: {
          value: 'N/A',
          percent: 'N/A',
        },
        change_30d: {
          value: 'N/A',
          percent: 'N/A',
        },
      },
    },
  },
  createPortfolio: { active: false, mode: 'live' },
  currencies: { data: [], loading: false },
  stats: { data: null, loading: false },
  vezgoIdentifiers: [],
  alerts: { data: null, loading: false },
  marketData: { data: null, loading: false },
  news: { data: null, loading: false },
  tweets: { data: null, loading: false },
  reddit: { data: null, loading: false },
  gainLossStats: { data: null, loading: false },
  allocations: { data: null, loading: true },
  benchmark: { data: null, loading: true },
  drawDown: { data: null, loading: true },
  config: {
    palette: 'dark',
    statsVisible: false,
    statTableColumns: null,
  },
  article: {
    data: null,
    loading: false,
  },
  editStatRowId: undefined,
  scenario: {
    selected: null,
    data: null,
    loading: false,
  },
  tokens: {
    accessToken: null,
    idToken: null,
  },
  strategyList: [],
  selectedStrategy: null,
  portfolioList: {},
  optimizeParams: null,
  portfolioId: 0,
  userId: 0,
};

export default function portfolioReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_LOGIN_TOKENS: {
      return {
        ...state,
        tokens: action.payload,
      };
    }
    case SET_CREATE_PORTFOLIO: {
      return {
        ...state,
        createPortfolio: {
          active: action.payload.active,
          mode: action.payload.mode ?? state.createPortfolio.mode,
        },
      };
    }
    case SET_USER_ID: {
      return {
        ...state,
        userId: action.payload,
      };
    }
    case SET_PORTFOLIO_LIST: {
      return {
        ...state,
        portfolioList: action.payload,
      };
    }
    case SET_STRATEGY_LIST: {
      return {
        ...state,
        strategyList: action.payload,
      };
    }
    case SET_SELECTED_STRATEGY: {
      return {
        ...state,
        selectedStrategy: action.payload,
      };
    }
    case SET_PORTFOLIO_ID: {
      return {
        ...state,
        portfolioId: action.payload,
      };
    }
    case CURRENCIES_GET_REQUEST: {
      return {
        ...state,
        currencies: {
          ...state.currencies,
          loading: true,
        },
      };
    }
    case STATS_GET_REQUEST: {
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: true,
        },
      };
    }
    case ALERTS_GET_REQUEST: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          loading: true,
        },
      };
    }
    case CURRENCIES_GET_SUCCESS: {
      return {
        ...state,
        currencies: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case STATS_GET_SUCCESS: {
      return {
        ...state,
        stats: {
          data: action.payload.stats,
          loading: false,
        },
        vezgoIdentifiers: action.payload.vezgoIdentifiers,
      };
    }
    case GAIN_LOSS_GET_SUCCESS: {
      return {
        ...state,
        gainLossStats: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_MARKET_DATA: {
      return {
        ...state,
        marketData: {
          ...state.marketData,
          loading: true,
        },
      };
    }
    case LOAD_MARKET_DATA_SUCCESS: {
      return {
        ...state,
        marketData: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_ALLOCATIONS: {
      return {
        ...state,
        allocations: {
          ...state.allocations,
          loading: true,
        },
      };
    }
    case LOAD_ALLOCATIONS_SUCCESS: {
      return {
        ...state,
        allocations: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_BENCHMARK_SUCCESS: {
      return {
        ...state,
        benchmark: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case SET_OPTIMIZATION_PARAMS: {
      return {
        ...state,
        optimizeParams: action.payload,
      };
    }
    case LOAD_DRAWDOWN: {
      return {
        ...state,
        drawDown: {
          ...state.drawDown,
          loading: true,
        },
      };
    }
    case LOAD_DRAWDOWN_SUCCESS: {
      return {
        ...state,
        drawDown: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case ALERTS_GET_SUCCESS: {
      return {
        ...state,
        alerts: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_NEWS: {
      return {
        ...state,
        news: {
          ...state.news,
          loading: true,
        },
      };
    }
    case LOAD_NEWS_SUCCESS: {
      return {
        ...state,
        news: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_SCENARIO: {
      return {
        ...state,
        scenario: {
          ...state.scenario,
          loading: true,
        },
      };
    }
    case LOAD_SCENARIO_SUCCESS: {
      return {
        ...state,
        scenario: {
          ...state.scenario,
          data: action.payload,
          loading: false,
        },
      };
    }
    case SELECT_SCENARIO: {
      return {
        ...state,
        scenario: {
          ...state.scenario,
          selected: action.payload,
        },
      };
    }
    case LOAD_ARTICLE: {
      return {
        ...state,
        article: {
          data: null,
          loading: true,
        },
      };
    }
    case LOAD_ARTICLE_SUCCESS: {
      return {
        ...state,
        article: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_PORTFOLIO: {
      return {
        ...state,
        total: {
          ...state.total,
          loading: true,
        },
      };
    }
    case LOAD_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        total: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_TWEETS_SUCCESS: {
      return {
        ...state,
        tweets: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_REDDIT_SUCCESS: {
      return {
        ...state,
        reddit: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case SET_PALETTE: {
      return {
        ...state,
        config: {
          ...state.config,
          palette: action.payload,
        },
      };
    }
    case SET_STATS_COLUMNS: {
      return {
        ...state,
        config: {
          ...state.config,
          statTableColumns: action.payload,
        },
      };
    }
    case SET_STATS_VISIBILITY: {
      return {
        ...state,
        config: {
          ...state.config,
          statsVisible: !state.config.statsVisible,
        },
      };
    }
    case SET_EDIT_STAT_ROW: {
      return {
        ...state,
        editStatRowId: action.payload,
      };
    }
    case APPLY_CONFIG: {
      return {
        ...state,
        config: action.payload,
      };
    }
    default:
      return state;
  }
}
