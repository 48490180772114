import {
  space,
  colors,
  letterSpacings,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  headings,
} from './common';

const site = {
  background: '#101E2D', // colors.blue,
  color: colors.white,
  tableHeaderBackground: '#223141',
  bannerBackground: '#223141',
  bannerBorder: '#55B1EC',
  selectBorder: '#D9D9D9',
  selectTabBorder: colors.white,
  tabTitleColor: colors.white,
  filtersBorder: colors.white,
  bannerShadow: '',
  cellBorder: '#55B1EC',
  cellColor: 'white',
  currencyTabColor: 'white',
  selectorText: 'white',
  selectorTextHover: colors.darkBlue,
  pageHeaderText: '#55B1EC',
  tickerBorder: colors.accent,
  chartLabel: colors.white,
  disabled: colors.white,
  sectionTitleBackground: '#0b2c43',
};

const theme = {
  site,
  space,
  colors,
  letterSpacings,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  headings,
};

export default theme;
