import { DashboardActionTypes } from './actionTypes';
import { DASHBOARD_TEST, LOAD_SECTOR_PERFORMANCE, LOAD_SUBSECTOR_PERFORMANCE } from './constants';

interface DashboardState {
  sectorPerformance: any[],
  subSectorPerformance: any[],
}

export const initialState: DashboardState = {
  sectorPerformance: [],
  subSectorPerformance: [],
};

export function dashboardReducer(
  state = initialState,
  action: DashboardActionTypes,
): DashboardState {
  switch (action.type) {
    case DASHBOARD_TEST:
      return {
        ...state,
      };
    case LOAD_SECTOR_PERFORMANCE:
      return {
        ...state,
        sectorPerformance: action.payload,
      };
    case LOAD_SUBSECTOR_PERFORMANCE:
      return {
        ...state,
        subSectorPerformance: action.payload,
      };
    default:
      return state;
  }
}
