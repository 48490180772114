import { combineReducers } from 'redux';

import { accountReducer } from '../account/reducers';
import { currencyProfileReducer } from '../currencyProfile/reducers';
import { dashboardReducer } from '../dashboard/reducers';
import portfolioReducer from '../portfolio/reducers';

const rootReducer = combineReducers({
  account: accountReducer,
  currencyProfile: currencyProfileReducer,
  dashboard: dashboardReducer,
  portfolio: portfolioReducer,
});

export default rootReducer;
