const space = [0, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512];

const colors = {
  lightBlack: '#444',
  black: '#333333',
  white: '#ffffff',
  gray: '#ccc',
  offWhite: '#ecf0f1',
  accent: '#55B1EC',
  lightBlue: '#34495e',
  brightBlue: '#6b8198',
  blue: '#2C3E50',
  darkBlue: '#2d3e50',
  yellow: '#f1c40f',
  green: '#6a9e80',
  orange: '#e77e22',
  positive: '#39DF9F',
  negative: '#F9423D',
  tempLink: '#84bbe0',
  transparent: '#ffffff00',
  lightGray: '#111111',
  neutral: '#cccccc',
  lessPositive: '#88ebc5',
  lessNegative: '#fb8d8a',
  buyPositive: '#6BD7A4',
  navy: '#223141',
  bodyText: '#647087',
};

const fontSizes = ['12px', '13px', '16px', '18px', '20px', '56px'];

const fonts = {
  serif: 'Open Sans',
  sansSerif: 'sans-serif',
  helvetica: 'Helvetica',
  proximaNova: 'proxima-nova, sans-serif',
};

const fontWeights = {
  light: 300,
  regular: 400,
  midBold: 600,
  bold: 700,
};

const lineHeights = {
  solid: 1,
  title: 1.05,
  copy: 1.5,
  wide: 1.8,
};

const letterSpacings = {
  normal: 'normal',
  tracked: '0.1em',
  tight: '-0.05em',
  mega: '0.25em',
};

const radii = {
  none: 0,
  normal: '4px',
  round: '24px',
  circle: '50%',
};

const borders = {
  none: 'none',
  normal: '1px solid',
};

const shadows = {
  normal: '0 10px 32px #eceef1',
};

// Variants

const headings = {
  h1: {
    fontSize: fontSizes[4],
    fontWeight: fontWeights.regular,
  },
  h2: {
    fontSize: fontSizes[3],
    marginBottom: space[3],
  },
  h3: {
    fontSize: fontSizes[2],
    marginBottom: space[3],
  },
  h4: {
    fontSize: fontSizes[1],
    marginBottom: space[2],
  },
  h5: {
    fontSize: fontSizes[1],
    marginBottom: space[2],
  },
  h6: {
    fontSize: fontSizes[0],
    marginBottom: space[2],
  },
};

const buttons = {
  primary: {
    background: colors.accent,
    fontSize: fontSizes[2],
    fontWeight: fontWeights.bold,
    paddingTop: space[2],
    paddingBottom: space[2],
    paddingLeft: space[4],
    paddingRight: space[4],
    borderRadius: radii.none,
    color: colors.lightGray,
  },
  secondary: {
    background: colors.lightGray,
    fontSize: fontSizes[2],
    fontWeight: fontWeights.bold,
    paddingTop: space[2],
    paddingBottom: space[2],
    paddingLeft: space[4],
    paddingRight: space[4],
    borderRadius: radii.none,
    color: colors.blue,
  },
  block: {
    background: colors.lightBlue,
    border: borders.normal,
    borderColor: colors.brightBlue,
    borderTop: 'none',
    borderRadius: radii.none,
    color: colors.tempLink,
    width: '100%',
  },
  borderless: {
    background: colors.transparent,
    border: borders.none,
    color: colors.accent,
    fontSize: fontSizes[2],
    '&:hover': {
      color: colors.white,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '&:active': {
      outline: borders.none,
    },
    '&:focus': {
      outline: borders.none,
    },
  },
};

const tables = {
  normal: {
    background: colors.lightBlue,
  },
  header: {
    background: colors.darkBlue,
    borderBottom: 'none',
  },
};

const blocks = {
  normal: {
    background: colors.lightBlue,
  },
  header: {
    background: colors.darkBlue,
    borderBottom: 'none',
  },
  transparent: {
    background: colors.transparent,
  },
};

export {
  space,
  colors,
  fontSizes,
  fonts,
  fontWeights,
  lineHeights,
  letterSpacings,
  radii,
  borders,
  shadows,
  buttons,
  headings,
  tables,
  blocks,
};
