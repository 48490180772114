export const CURRENCIES_GET_REQUEST = 'portfolio/CURRENCIES_GET_REQUEST';
export const CURRENCIES_GET_ERROR = 'portfolio/CURRENCIES_GET_ERROR';
export const CURRENCIES_GET_SUCCESS = 'portfolio/CURRENCIES_GET_SUCCESS';
export const STATS_GET_REQUEST = 'portfolio/STATS_GET_REQUEST';
export const STATS_GET_ERROR = 'portfolio/STATS_GET_ERROR';
export const STATS_GET_SUCCESS = 'portfolio/STATS_GET_SUCCESS';
export const ALERTS_GET_REQUEST = 'portfolio/ALERTS_GET_REQUEST';
export const ALERTS_GET_ERROR = 'portfolio/ALERTS_GET_ERROR';
export const ALERTS_GET_SUCCESS = 'portfolio/ALERTS_GET_SUCCESS';
export const SET_PALETTE = 'portfolio/config/SET_PALETTE';
export const SET_STATS_COLUMNS = 'portfolio/config/SET_STATS_COLUMNS';
export const SET_STATS_VISIBILITY = 'portfolio/config/SET_STATS_VISIBILITY';
export const ADD_STATS_ROWS = 'portfolio/ADD_STATS_ROWS';
export const REMOVE_STAT_ROW = 'portfolio/REMOVE_STAT_ROW';
export const SET_EDIT_STAT_ROW = 'portfolio/SET_EDIT_STAT_ROW';
export const SAVE_STAT_ROW = 'portfolio/SAVE_STAT_ROW';
export const APPLY_CONFIG = 'portfolio/APPLY_CONFIG';
export const LOAD_NEWS = 'portfolio/LOAD_NEWS';
export const LOAD_NEWS_ERROR = 'portfolio/LOAD_NEWS_ERROR';
export const LOAD_NEWS_SUCCESS = 'portfolio/LOAD_NEWS_SUCCESS';
export const LOAD_TWEETS = 'portfolio/LOAD_TWEETS';
export const LOAD_TWEETS_ERROR = 'portfolio/LOAD_TWEETS_ERROR';
export const LOAD_TWEETS_SUCCESS = 'portfolio/LOAD_TWEETS_SUCCESS';
export const LOAD_REDDIT = 'portfolio/LOAD_REDDIT';
export const LOAD_REDDIT_ERROR = 'portfolio/LOAD_REDDIT_ERROR';
export const LOAD_REDDIT_SUCCESS = 'portfolio/LOAD_REDDIT_SUCCESS';
export const LOAD_PORTFOLIO = 'portfolio/LOAD_REDDIT';
export const LOAD_PORTFOLIO_ERROR = 'portfolio/LOAD_REDDIT_ERROR';
export const LOAD_PORTFOLIO_SUCCESS = 'portfolio/LOAD_PORTFOLIO_SUCCESS';
export const LOAD_ARTICLE = 'portfolio/LOAD_ARTICLE';
export const LOAD_ARTICLE_SUCCESS = 'portfolio/LOAD_ARTICLE_SUCCESS';
export const LOAD_ARTICLE_ERROR = 'portfolio/LOAD_ARTICLE_ERROR';
export const SELECT_SCENARIO = 'portfolio/SELECT_SCENARIO';
export const LOAD_SCENARIO = 'portfolio/LOAD_SCENARIO';
export const LOAD_SCENARIO_SUCCESS = 'portfolio/LOAD_SCENARIO_SUCCESS';
export const LOAD_SCENARIO_ERROR = 'portfolio/LOAD_SCENARIO_ERROR';
export const GAIN_LOSS_GET_SUCCESS = 'portfolio/GAIN_LOSS_GET_SUCCESS';
export const LOAD_ALLOCATIONS = 'portfolio/LOAD_ALLOCATIONS';
export const LOAD_ALLOCATIONS_SUCCESS = 'portfolio/LOAD_ALLOCATIONS_SUCCESS';
export const LOAD_ALLOCATIONS_ERROR = 'portfolio/LOAD_ALLOCATIONS_ERROR';
export const SET_PORTFOLIO_ID = 'portfolio/SET_PORTFOLIO_ID';
export const SET_LOGIN_TOKENS = 'auth/SET_LOGIN_TOKENS';
export const SET_PORTFOLIO_LIST = 'portfolio/SET_PORTFOLIO_LIST';
export const SET_USER_ID = 'auth/SET_USER_ID';
export const LOAD_MARKET_DATA = 'portfolio/LOAD_MARKET_DATA';
export const LOAD_MARKET_DATA_SUCCESS = 'portfolio/LOAD_MARKET_DATA_SUCCESS';
export const LOAD_MARKET_DATA_ERROR = 'portfolio/LOAD_MARKET_DATA_ERROR';
export const LOAD_DRAWDOWN = 'portfolio/LOAD_DRAWDOWN';
export const LOAD_DRAWDOWN_SUCCESS = 'portfolio/LOAD_DRAWDOWN_SUCCESS';
export const LOAD_DRAWDOWN_ERROR = 'portfolio/LOAD_DRAWDOWN_ERROR';
export const SET_CREATE_PORTFOLIO = 'portfolio/SET_CREATE_PORTFOLIO';
export const LOAD_BENCHMARK = 'portfolio/LOAD_BENCHMARK';
export const LOAD_BENCHMARK_SUCCESS = 'portfolio/LOAD_BENCHMARK_SUCCESS';
export const LOAD_BENCHMARK_ERROR = 'portfolio/LOAD_BENCHMARK_ERROR';
export const SET_OPTIMIZATION_PARAMS = 'portfolio/SET_OPTIMIZATION_PARAMS';
export const SET_STRATEGY_LIST = 'portfolio/SET_STRATEGY_LIST';
export const SET_SELECTED_STRATEGY = 'portfolio/SET_SELECTED_STRATEGY';
